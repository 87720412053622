import React, { useRef, useState } from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE, SIZE } from 'baseui/button'
import Emoji from './Emoji'

const BenefitsSlider = () => {
  const [css] = useStyletron()
  const [isDown, setIsDown] = useState(false)
  const [left, setLeft] = useState(0)
  const [startX, setStartX] = useState(0)
  const slider = useRef()

  const benefitsContainer = css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  })

  const benefits = css({
    width: '100%',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    // minHeight: '250px',
    display: 'flex',
    overflowX: 'auto',
    cursor: 'grab',
    /* width */
    '::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
      borderRadius: '5px',
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  })

  const benefitCard = css({
    padding: '1rem',
    margin: '0.6rem 0.5rem',
    minWidth: '280px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
  })

  const lastCard = css({
    padding: '1rem',
    margin: '1rem',
    minWidth: '300px',
    // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
    textAlign: 'center',
  })

  const mousedown = e => {
    setIsDown(true)
    setStartX(e.pageX - slider.current.offsetLeft)
    setLeft(slider.current.scrollLeft)
  }
  const mouseleave = () => {
    setIsDown(false)
  }

  const mouseup = () => {
    setIsDown(false)
  }

  const mousemove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.current.offsetLeft
    const walk = (x - startX) * 2 //scroll-fast
    slider.current.scrollLeft = left - walk
  }

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }
  return (
    <div className={benefitsContainer}>
      <div
        className={benefits}
        ref={slider}
        onMouseDown={mousedown}
        onMouseLeave={mouseleave}
        onMouseUp={mouseup}
        onMouseMove={mousemove}
      >
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🤩" size={25} /> Conversión
          </h3>
          <p>
            Cuando un usuario ve un <strong>wa.link </strong> sabe que entrará
            en contacto directo con <strong>el negocio en WhatsApp</strong>,
            aumentando la conversión.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📺" size={25} /> Omnicanalidad
          </h3>
          <p>
            Los links premium son perfectos para medios tradicionales (como
            piezas gráficas, radio, televisión, etc.), pues es más fácil{' '}
            <strong>recordar un nombre que un número de teléfono.</strong>
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="⚙️" size={25} /> Modificable
          </h3>
          <p>
            A los links premium les puedes{' '}
            <strong>modificar el mensaje, el número de WhatsApp </strong>e
            incluso la URL, lo cual los hace versátiles para todo tipo de
            campañas.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📈" size={25} /> Analítica
          </h3>
          <p>
            Mide el rendimiento de tu wa.link en todas las plataformas con
            analítica. Consulta el <strong>número de clics</strong> por hora,
            día o mes, <strong>fuentes de trafico</strong>,{' '}
            <strong>sistema operativo</strong> y <strong>ubicación</strong>.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🔎" size={25} /> Buscable
          </h3>
          <p>
            Las personas pueden{' '}
            <strong>
              {' '}
              encontrar tu WhatsApp sin necesidad de saber tu número de télefono
            </strong>
            , solo con el nombre o descripcion del negocio a través de nuestro
            buscador.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🔗" size={25} /> Link corto
          </h3>
          <p>
            Los links <strong>incluyen el mensaje personalizado </strong>sin
            afectar la extensión o presentación del link.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="️🛍️" size={25} /> Catálogo{' '}
          </h3>
          <p>
            Con Walink Premium puedes crear{' '}
            <strong>links personalizados para tu catálogo </strong>de WhatsApp
            Business.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="️🕵️" size={25} /> Privacidad
          </h3>
          <p>
            La privacidad es importante, por eso, el link{' '}
            <strong>no expone tu número</strong>, ni mensaje de forma pública en
            Internet sin tu consentimiento.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📱" size={25} /> Accesibilidad
          </h3>
          <p>
            Además del link, obtienes un <strong> código QR </strong>con la
            misma información de tu enlace.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="💬" size={25} /> Prioridad
          </h3>
          <p>
            Si tu número no está expuesto garantizas que un usuario siempre
            abrirá <strong>primero un chat de WhatsApp </strong>antes que
            llamarte.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🎯" size={25} /> Certeza
          </h3>
          <p>
            Los links son case insensitive, es decir, un usuario podrá
            escribirte independientemente si usa:
            <br />
            <strong> wa.link/TuNegocio</strong>
            <br />
            <strong> wa.link/TUNEGOCIO</strong>
            <br />
            <strong> wa.link/tunegocio</strong>
          </p>
        </div>

        <div className={lastCard}>
          <h2>¡Regístrate ya!</h2>
          <p>Y adquiere tu primer Walink Premium por 6 USD al año</p>

          <Button
            onClick={goToApp}
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            <Emoji symbol={'👑'} size={20} /> Registrarme
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BenefitsSlider
