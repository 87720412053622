import React from 'react'
import { useStyletron } from 'baseui'
import { Paragraph2 } from 'baseui/typography'
import { ListItem, ListItemLabel } from 'baseui/list'
import { Check } from 'baseui/icon'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CTA from '../components/RegisterCTA'
import PremiumHero from '../components/PremiumHero'
import SearchLink from '../components/SearchLink'
import BenefitsSlider from '../components/BenefitsSlider'
// import Emoji from '../components/Emoji'
import MultilinkSection from '../components/MultilinkSection'
import {
  FiPlusCircle,
  FiEdit,
  FiExternalLink,
  FiBarChart,
  FiTag,
} from 'react-icons/fi'
import { FcSearch } from 'react-icons/fc'
import Pricing from '../components/Pricing'

export default () => {
  const [css] = useStyletron()

  const cardClass = css({
    height: '100%',
    width: '300px',
    margin: '30px auto',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',

    background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
  })

  const cardTitle = css({
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#fff',
    padding: '5px',
  })

  const iconClass = css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  })

  const bannerCard = css({
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',
    padding: '1.5rem',
  })

  const iconCenter = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <Layout page="premium" hero={<PremiumHero />}>
      <SEO
        title="Crea tu link de WhatsApp con tu marca"
        description="Crea tu propio link de WhatsApp con URL personalizada, analítica y control. Con Walink Premium aumenta la recordación de tu marca y obtén mas beneficios."
        hreflang={[
          {
            rel: 'alternate',
            hrefLang: 'es',
            href: 'https://crear.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: 'https://create.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'pt',
            href: 'https://criar.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link/premium',
          },
        ]}
      />
      <div>
        <div style={{ margin: '4rem 0' }}>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '1.8rem',
              marginBottom: '2.5rem',
            }}
            id="search"
          >
            Busca un link personalizado de WhatsApp
          </h2>
          <SearchLink />
        </div>
      </div>

      <div style={{ marginBottom: '4rem', marginTop: '4rem' }}>
        <div
          className={css({
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '3rem',
          })}
        >
          <h2>¿Por qué Walink Premium es ideal para tu negocio?</h2>
          <Paragraph2>
            Descubre por qué nuestro servicio es la mejor solución para atraer
            más clientes a tu chat de WhatsApp
          </Paragraph2>
        </div>
        <BenefitsSlider />
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h2>¿Qué obtengo con el plan premium?</h2>
        <Paragraph2>
          <strong>Paga solo $6 USD por link al año</strong>. Cada link cuenta
          con las siguientes características:
        </Paragraph2>
      </div>
      <div
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
        })}
      >
        <div className={cardClass}>
          <div className={iconClass}>
            <FiTag size={45} />
          </div>
          <h3 className={cardTitle}>Links personalizados</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>TuNegocio</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>TuMarca</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>
              wa.link/<strong>TuCatalogo</strong>
            </ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiEdit size={45} />
          </div>
          <h3 className={cardTitle}>Actualiza información</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Cambia el número o el mensaje del link.
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Crea links para el catálogo de WhatsApp
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>
              Cambia la URL personalizada cuando quieras.
            </ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiBarChart size={45} />
          </div>
          <h3 className={cardTitle}>Analítica</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Clics en el enlace diarios y por hora.
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>Ubicación de los clics.</ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>Fuentes de los clics</ListItemLabel>
          </ListItem>
        </div>
      </div>
      <div className={iconCenter}>
        <FiPlusCircle size="30px" />
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <div className={bannerCard}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3 style={{ margin: 0 }}>
              Adicional... ¡haz que tus clientes te encuentren!
            </h3>
            <FcSearch size="30px" style={{ marginLeft: '5px' }} />
          </div>
          <Paragraph2>
            Aparece como resultado en nuestro{' '}
            <strong>
              <a
                href="https://open.wa.link/search"
                target="_blank"
                rel="noopener noreferrer"
              >
                buscador
                <span style={{ marginLeft: '2px' }}>
                  <FiExternalLink size="16" />
                </span>
              </a>
            </strong>
            , así tus clientes pueden encontrar tu WhatsApp solo con saber el
            nombre de tu negocio.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h3>Y más beneficios...</h3>
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <MultilinkSection />
      </div>
      <Pricing />
      <CTA
        description={
          <>
            Estás a pocos clics de aumentar las ventas de tu negocio a través de
            WhatsApp.
          </>
        }
        title="¿Estás listo para ser Premium?"
        currentPage="Premium"
      />
    </Layout>
  )
}
