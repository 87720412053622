import React from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE, SIZE } from 'baseui/button'
import { Paragraph3 } from 'baseui/typography'
import Emoji from './Emoji'

const MultilinkDescription = () => {
  const [css] = useStyletron()

  const card = css({
    padding: '1rem',
    margin: '0.6rem 0.5rem',
    minWidth: '280px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
  })

  const descriptionButton = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }

  return (
    <div className={card}>
      <div
        className={css({
          textAlign: 'center',
          marginBottom: '2rem',
        })}
      >
        <h2>
          <Emoji symbol={'⭐'} size={25} /> Página Multilink:{' '}
        </h2>
      </div>
      <Paragraph3>
        El servicio multilink habilitá una página web que agrupa todos los
        Walinks que quieras bajo una misma URL.{' '}
      </Paragraph3>
      <Paragraph3>
        Cada link Premium será asignado a un botón para que los usuarios te
        contacten fácilmente. Es la solución perfecta cuando tienes múltiples
        líneas de WhatsApp para tus tiendas, agentes, departamentos, catálogo,
        servicios o productos.
      </Paragraph3>
      <Paragraph3 style={{ marginBottom: 0 }}>Puedes personalizar:</Paragraph3>
      <ul className="multilinkBullets">
        <li>
          <Paragraph3>Tu Logo</Paragraph3>
        </li>
        <li>
          <Paragraph3>El nombre del negocio</Paragraph3>
        </li>
        <li>
          <Paragraph3>Descripción</Paragraph3>
        </li>
        <li>
          <Paragraph3>Sitio web</Paragraph3>
        </li>
      </ul>
      <Paragraph3>
        También puedes agregar tu política de privacidad para que los usuarios
        la acepten antes de contactarte.
      </Paragraph3>
      <Paragraph3>
        <small>
          * Este servicio está disponible de forma gratuita para usuarios con 2
          o más links activos en su plan.
        </small>
      </Paragraph3>
      <div className={descriptionButton}>
        <Button
          onClick={goToApp}
          shape={SHAPE.pill}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: {
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
              },
            },
          }}
        >
          <>
            <Emoji symbol={'🤩'} size={20} /> ¡Lo quiero ya!
          </>
        </Button>
      </div>
    </div>
  )
}

export default MultilinkDescription
